import { useMutation, useQuery } from '@apollo/client';
import type { Key } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import iconBack from '../../assets/icons/back.svg';
import routeLogo from '../../assets/icons/routes.svg';
import iconSearch from '../../assets/icons/search-normal-1.svg';
import iconTruck from '../../assets/icons/truck-tick.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import FilterMarkEditRoutes from '../../components/ui/common/FilterMarkEditRoutes';
import Searchbar from '../../components/ui/common/Searchbar';
import TruckCapacityBar from '../../components/ui/common/TruckCapacityBar';
import { LoaderScreen } from '../../components/ui/LoaderScreen';
import RouteEditCard from '../../components/ui/routes/edit/RouteEditCard';
import StopCardAdded from '../../components/ui/routes/edit/StopCardAdded';
import SuggestedVisitsList from '../../components/ui/routes/edit/SuggestedVisitsList';
import {
  FAT_BONES_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
} from '../../config/constants';
import { useModal } from '../../hooks/useModal';
import { useNotification } from '../../hooks/useNotification';
import { editRouteFiltersState } from '../../recoil/editRouteFilter';
import { cleanEditRouteFiltersState } from '../../recoil/editRouteFilterClean';
import { needRefreshState } from '../../recoil/needRefresh';
import { UPDATE_ROUTE } from '../../services/graphql/route/route-mutations';
import { GET_ROUTE_BY_ID } from '../../services/graphql/route/route-querys';
import { GET_SERVICE_FOR_ROUTE_EDIT } from '../../services/graphql/service/service-querys';
import Map from '../../services/map/Mapbox';
import type {
  GetRouteByIdResponse,
  SingleStop,
  StopToUpdate,
} from '../../types/route';
import type { GetSuggestedVisitsResponse } from '../../types/service';

const RouteEditView = () => {
  const { id: routeId } = useParams();
  const { t } = useTranslation('common');
  const { handleOpenEditRouteModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyUpdateError, notifyUpdateOk } = useNotification();

  // ==== STATES ====
  const [readyToVisit, setReadyToVisit] = useState<boolean>(true);
  const [visualReadyToVisit, setVisualReadyToVisit] = useState<boolean>(true);

  const [searchInput, setSearchInput] = useState<string>('');

  const [filters, setFilters] = useRecoilState(editRouteFiltersState);
  const cleanFilters = useRecoilValue(cleanEditRouteFiltersState);

  const [needRefresh] = useRecoilState(needRefreshState);
  const [truckCapacityKey, setTruckCapacityKey] = useState<number>(
    Math.random(),
  );
  const [localRouteData, setLocalRouteData] =
    useState<GetRouteByIdResponse | null>(null);
  const [localSuggestedVisitsData, setLocalSuggestedVisitsData] =
    useState<GetSuggestedVisitsResponse | null>(null);

  const [existingStops, setExistingStops] = useState<StopToUpdate[]>([]);

  const [totalStopGallons, setTotalStopGallons] = useState<number>(0);
  const [totalStopBarrels, setTotalStopBarrels] = useState<number>(0);

  // ==== QUERIES ====
  const { data: routeData, refetch: refetchRouteInfo } =
    useQuery<GetRouteByIdResponse>(GET_ROUTE_BY_ID, {
      variables: { routeId },
    });

  const {
    data: suggestedVisitsData,
    refetch: refetchSuggestedVisits,
    loading: loadingSuggestedVisits,
  } = useQuery<GetSuggestedVisitsResponse>(GET_SERVICE_FOR_ROUTE_EDIT, {
    variables: {
      serviceType: localRouteData?.getRouteById.data.service,
      ready_to_visit: true,
    },
    skip: !localRouteData?.getRouteById.data.service,
  });

  // ==== MUTATIONS ====
  const [updateRoute] = useMutation(UPDATE_ROUTE, {
    onCompleted: () => {
      setTruckCapacityKey(Math.random());
      refetchRouteInfo();
      refetchSuggestedVisits();
      notifyUpdateOk();
    },
    onError: () => {
      notifyUpdateError();
    },
  });

  // ==== FUNCTIONS ====
  const refreshTruck = () => {
    setTruckCapacityKey(Math.random());
    refetchRouteInfo();
  };

  const filteredSuggestedVisitsData =
    localSuggestedVisitsData?.getSuggestedVisits.data.filter((entry) => {
      const clientId = entry.client_id;

      // Verificar si el clientId no está presente en routeData?.getRouteById.data?.stops
      const isClientIdPresent = routeData?.getRouteById.data?.stops.some(
        (stop) => stop.client_service.client_id === clientId,
      );

      return !isClientIdPresent;
    });

  // ==== HANDLERS ====

  const checkIsReadyToVisitRendering = () => {
    // If only ready to visit is used then set ready to visit kind of view

    const {
      ready_to_visit: readyToVisitFilter,
      client: clientFilter,
      serviceType: serviceTypeFilter,
      ...otherFilters
    } = filters || {};

    const areOtherFiltersEmpty = Object.values(otherFilters).every(
      (value) => value === undefined || value === null || value === '',
    );

    if (filters === null || filters === undefined) {
      return;
    }

    if (!areOtherFiltersEmpty) {
      setReadyToVisit(false);
      setVisualReadyToVisit(false);
      return;
    }

    if (
      readyToVisitFilter &&
      clientFilter &&
      serviceTypeFilter &&
      areOtherFiltersEmpty
    ) {
      setReadyToVisit(true);
      setVisualReadyToVisit(true);
      return;
    }

    if (readyToVisitFilter === true && !clientFilter) {
      setReadyToVisit(true);
      setVisualReadyToVisit(true);
      return;
    }

    if (clientFilter && readyToVisitFilter === false && serviceTypeFilter) {
      setReadyToVisit(false);
      setVisualReadyToVisit(false);
    }
  };

  const handleResetFilters = () => {
    return new Promise<void>((resolve) => {
      setFilters(cleanFilters);
      resolve();
    });
  };

  const handleToggleReadyToVisitMode = async () => {
    await handleResetFilters();
    setSearchInput('');

    setFilters({
      ready_to_visit: true,
      client: null,
      serviceType: [localRouteData!.getRouteById.data.service],
    });
  };

  const handleToggleSearchMode = () => {
    setSearchInput('');
    setFilters({
      ready_to_visit: undefined,
      client: null,
      serviceType: [localRouteData!.getRouteById.data.service],
    });
    setVisualReadyToVisit(false);
  };

  // Debounce Name filtering
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      let newFilters: Record<string, any> = {};

      newFilters = { ...filters };

      if (inputValue) {
        newFilters.client = inputValue;
      } else {
        newFilters.client = null;
      }

      setFilters(newFilters);
    }, 500);
  };
  const handleGoBack = () => {
    navigate(`/dashboard/routes/${routeId}`);
  };

  // ==== EFFECTS ===

  useEffect(() => {
    if (suggestedVisitsData && suggestedVisitsData.getSuggestedVisits) {
      setLocalSuggestedVisitsData(suggestedVisitsData);
    }

    if (routeData && routeData.getRouteById && routeData.getRouteById.data) {
      setLocalRouteData(routeData);

      const totalStopGallonsArray = routeData.getRouteById.data.stops.map(
        (stop) => {
          return stop.stop.estimated_gallons || 0;
        },
      );
      const totalStopBarrelsArray = routeData.getRouteById.data.stops.map(
        (stop) => {
          return stop.stop.estimated_barrel || 0;
        },
      );

      const totalGallons = totalStopGallonsArray.reduce(
        (total, currentValue) => total + currentValue,
        0,
      );
      const totalBarrels = totalStopBarrelsArray.reduce(
        (total, currentValue) => total + currentValue,
        0,
      );

      setTotalStopGallons(totalGallons);
      setTotalStopBarrels(totalBarrels);
    }
  }, [suggestedVisitsData, routeData]);

  useEffect(() => {
    refetchSuggestedVisits({
      ...filters,
      ready_to_visit: filters?.ready_to_visit || undefined,
    });

    checkIsReadyToVisitRendering();
  }, [filters, needRefresh]);

  useEffect(() => {
    checkIsReadyToVisitRendering();
  }, [readyToVisit]);

  useEffect(() => {
    if (localRouteData?.getRouteById.data.service !== undefined) {
      setFilters(cleanFilters);
      setFilters((prev) => ({
        ...prev,
        serviceType: localRouteData.getRouteById.data.service,
        ready_to_visit: true,
      }));
    }
  }, [location.key, localRouteData?.getRouteById.data.service]);

  useEffect(() => {
    if (localRouteData?.getRouteById.data.stops) {
      const stopsToUpdate: StopToUpdate[] =
        localRouteData?.getRouteById.data.stops.map((singleStop) => ({
          stopId: singleStop.stop.id,
          client_id: singleStop.client_service.client_id,
          service_id: singleStop.client_service.id,
          estimated_gallons: singleStop.stop.estimated_gallons,
          estimated_barrel: singleStop.stop.estimated_barrel || 0,
        }));

      setExistingStops(stopsToUpdate);
    }
  }, [localRouteData?.getRouteById.data.stops]);

  return (
    <section>
      <div className="fixed top-0 z-20 w-full bg-white">
        <Breadcrumb
          title={t('routes.title-h1')}
          image={routeLogo}
          secondTitle={t('routes.edit-route')}
          thirdTitle={localRouteData?.getRouteById.data?.route_name}
        />
      </div>
      <div className="mt-[30px] grid w-full grid-cols-1 gap-x-5 lg:grid-cols-3">
        {/* Suggested Visits */}
        <div className="">
          <div className="flex h-[30px] w-[300px] gap-6">
            <button
              onClick={() => handleToggleReadyToVisitMode()}
              className={`flex items-start gap-2 hover:border-b-2 ${
                visualReadyToVisit ? 'border-b-2' : ''
              }`}
            >
              <img className="pt-1" src={iconTruck} alt="icon truck" />
              <span
                className={`text-lg text-green ${
                  visualReadyToVisit ? 'font-bold' : 'font-medium'
                }`}
              >
                {t('routes.ready-to-visit')}
              </span>
            </button>
            <button
              onClick={() => handleToggleSearchMode()}
              className={`flex items-start gap-2 hover:border-b-2 ${
                !visualReadyToVisit ? 'border-b-2' : ''
              }`}
            >
              <img className="pt-1" src={iconSearch} alt="icon truck" />
              <span
                className={`text-lg font-bold text-green ${
                  !visualReadyToVisit ? 'font-bold' : 'font-medium'
                }`}
              >
                {t('routes.search')}
              </span>
            </button>
          </div>
          {localSuggestedVisitsData && (
            <div className="mt-5">
              <Searchbar
                handleOpenFilterModal={handleOpenEditRouteModal}
                filterValue={searchInput}
                onChange={handleSearchInput}
              />
            </div>
          )}
          {localSuggestedVisitsData && (
            <div className="my-2 flex w-full flex-wrap gap-2">
              {filters &&
                Object.entries(filters).map(([name, value]) => {
                  if (
                    value !== null &&
                    value !== undefined &&
                    name !== 'client' &&
                    name !== 'serviceType' &&
                    name !== 'orderDirection' &&
                    name !== 'orderBy' &&
                    name !== 'limit' &&
                    name !== 'offset' &&
                    !(name === 'ready_to_visit' && visualReadyToVisit === true)
                  ) {
                    return (
                      <FilterMarkEditRoutes
                        key={name}
                        filterName={name}
                        filterValue={value}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          )}

          <div>
            {localSuggestedVisitsData && (
              <h1 className="my-2 text-xl font-semibold">
                {visualReadyToVisit && t('routes.clients-ready-to-visit')}
                {!visualReadyToVisit && t('routes.search-clients')}
              </h1>
            )}

            {routeId &&
            localRouteData &&
            localRouteData?.getRouteById.data.service ? (
              <SuggestedVisitsList
                loading={loadingSuggestedVisits}
                suggestedVisitsData={filteredSuggestedVisitsData || undefined}
                readyToVisit={visualReadyToVisit}
                mutation={updateRoute}
                existingStops={existingStops}
                routeId={routeId}
              />
            ) : (
              <LoaderScreen />
            )}
          </div>
        </div>
        {/* Route info */}
        <div className="relative">
          {/* <div className="sticky bottom-0"> */}
          <div className="mb-4 flex w-full items-center justify-between">
            <div className="text-xl font-semibold text-zinc-800">
              {localRouteData?.getRouteById.data?.route_name}
            </div>
          </div>
          <div className="mb-4">
            {localRouteData && localRouteData?.getRouteById.data && (
              <RouteEditCard
                route={localRouteData?.getRouteById.data}
                refetchRoutes={refetchRouteInfo}
              />
            )}
          </div>
          <div className="relative my-6 rounded-2xl">
            <Map
              stops={
                localRouteData?.getRouteById.data?.stops.map(
                  (stop: {
                    stop: { status: any };
                    client_service: {
                      client_coords: any;
                      client_name: string;
                    };
                  }) => ({
                    status: stop.stop.status,
                    clientCoords: stop.client_service.client_coords,
                    clientName: stop.client_service.client_name,
                  }),
                ) || []
              }
              isNotSticky
            />
          </div>
          {/* </div> */}
        </div>
        {/* Selected Stops */}
        <div className="">
          <div className="mb-4 flex w-full items-center justify-between">
            <div className="text-xl font-semibold text-zinc-800">
              {t('routes.route-route-stops')}
            </div>
            <button
              onClick={handleGoBack}
              id="back-to-route-button"
              className="inline-flex h-[33px] w-[84px] items-center justify-center gap-1 rounded-[5px] border border-gray-700 px-2 py-1"
            >
              <img className="h-[15px] w-[15px]" src={iconBack} alt="Go back" />
              <p className="text-md">Back</p>
            </button>
          </div>
          <div key={truckCapacityKey}>
            {localRouteData &&
              localRouteData?.getRouteById.data?.truck_id &&
              localRouteData?.getRouteById.data?.service !==
                HOOD_CLEANING_SERVICE_NAME &&
              localRouteData?.getRouteById.data?.service !==
                FAT_BONES_SERVICE_NAME && (
                <TruckCapacityBar
                  estimatedCapacity={totalStopGallons || 0}
                  truckId={localRouteData?.getRouteById.data?.truck_id}
                  service={localRouteData?.getRouteById.data?.service}
                />
              )}
            {localRouteData &&
              localRouteData?.getRouteById.data?.truck_id &&
              localRouteData?.getRouteById.data?.service ===
                FAT_BONES_SERVICE_NAME && (
                <TruckCapacityBar
                  estimatedCapacity={totalStopBarrels || 0}
                  truckId={localRouteData?.getRouteById.data?.truck_id}
                  service={localRouteData?.getRouteById.data?.service}
                />
              )}
          </div>
          <div className="my-2">
            {routeId &&
              routeData?.getRouteById.data?.stops.map(
                (stop: SingleStop, index: Key | null | undefined) => (
                  <StopCardAdded
                    key={index}
                    businessName={stop.client_service.client_name}
                    phoneNumber={stop.client_service.client_phone}
                    address={stop.client_service.client_address}
                    estGallons={stop.stop.estimated_gallons}
                    estBarrels={stop.stop.estimated_barrel || 0}
                    id={stop.stop.id}
                    clientId={stop.client_service.client_id}
                    serviceId={stop.client_service.id}
                    mutation={updateRoute}
                    refetchTruckCapacity={refreshTruck}
                    existingStops={existingStops}
                    routeId={routeId}
                    totalStopsEstimatedGallons={totalStopGallons}
                    totalStopsEstimatedBarrels={totalStopBarrels}
                    service={routeData?.getRouteById.data?.service}
                  />
                ),
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RouteEditView;

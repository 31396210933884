import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import callIcon from '../../../assets/icons/call.svg';
import clientIcon from '../../../assets/icons/clients-small.svg';
import emailIcon from '../../../assets/icons/email.svg';
import addressIcon from '../../../assets/icons/location.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { needRefreshState } from '../../../recoil/needRefresh';
import { GET_GEOCODING_SUGGESTIONS } from '../../../services/graphql/address/address-querys';
import { CREATE_CLIENT } from '../../../services/graphql/client/client-mutations';
import { EMAIL_REGEX, PHONE_REGEX } from '../../../utils/RegexValidation';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import InputField from '../../ui/common/InputField';

type Inputs = {
  name: string;
  phone: string;
  address: string;
  email: string;
};

const NewClient = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError, notifyAddressError } =
    useNotification();
  // Form

  const [addressFocused, setaddressFocused] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: '',
      phone: '',
      address: '',
      email: '',
    },
  });

  const breadcrumbTitle = `${t('clients.title-h1')} > ${t(
    'modalNewClient.newClient',
  )}`;

  const name = watch('name');
  const phone = watch('phone');
  const address = watch('address');
  const email = watch('email');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Query
  const [createClients, { loading: createClientLoading }] = useMutation<any>(
    CREATE_CLIENT,
    {
      variables: {
        clientName: name,
        phone,
        address,
        email,
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  const handleFormReset = () => {
    setValue('name', '');
    setValue('phone', '');
    setValue('address', '');
    setValue('email', '');
  };

  // Geocoding

  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const [showAddressList, setShowAddressList] = useState<boolean>(false);

  const { data: fetchAddressData } = useQuery<any>(GET_GEOCODING_SUGGESTIONS, {
    variables: {
      address: address || '',
    },
  });

  useEffect(() => {
    if (
      fetchAddressData &&
      fetchAddressData.getAddressSuggestions.code === 200
    ) {
      if (addressFocused) {
        setShowAddressList(true);
      }

      setAddressSuggestions(
        fetchAddressData.getAddressSuggestions.data.map(
          (item: any) => item.place,
        ),
      );
    }
  }, [fetchAddressData]);

  useEffect(() => {
    if (!addressFocused) {
      // We give time to hit the selected option
      setTimeout(() => {
        setShowAddressList(false);
      }, 200);
    }
    if (addressFocused && addressSuggestions.length > 0) {
      setShowAddressList(true);
    }
  }, [addressFocused]);

  const handleAddressSelection = (selectedOption: string) => {
    setSelectedAddress(selectedOption);
    setValue('address', selectedOption);
    setShowAddressList(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAddress = e.target.value;

    setValue('address', inputAddress);
    setSelectedAddress(inputAddress);

    if (inputAddress.trim() === '') {
      setShowAddressList(false);
    }
  };

  const validateAddress = (_selectedAddress: string) => {
    return addressSuggestions.includes(_selectedAddress);
  };

  // On Submit
  const onSubmit: SubmitHandler<Inputs> = () => {
    if (createClientLoading) {
      return;
    }

    const isValid = validateAddress(selectedAddress);

    if (isValid) {
      createClients();
      handleFormReset();
    } else {
      notifyAddressError();
    }
  };

  return (
    <>
      <Breadcrumb title={breadcrumbTitle} image={clientIcon} id="ClientModal" />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewClient.newClient')}
      </h1>
      <form className="my-6">
        {/* Name Input */}
        <InputField
          label={t('modalNewClient.clientName')}
          selectedValue={name}
          doNotUseSaveSelfButton
          type="text"
          errors={errors.name}
          register={register}
          registerName="name"
          validationSchema={{
            required: t('global.required'),
          }}
        />

        {/* Phone Input */}
        <InputField
          label={t('modalNewClient.phone')}
          selectedValue={phone}
          doNotUseSaveSelfButton
          type="text"
          icon={callIcon}
          errors={errors.phone}
          register={register}
          registerName="phone"
          validationSchema={{
            required: t('global.required'),
            pattern: {
              value: PHONE_REGEX,
              message: t('global.phone-error'),
            },
          }}
        />

        {/* Address Input */}
        <div className="my-2 h-[60px]">
          <label htmlFor="address" className="block text-[14px] font-[600]">
            {t('modalNewClient.address')}
          </label>
          <div
            className={`relative flex w-full border-x-0 border-b-2 border-t-0 border-b-graydark            
            ${
              get(errors, 'name') &&
              'border-x-0 border-b-2 border-t-0 border-b-red'
            }`}
          >
            <img src={addressIcon} alt="address icon" />

            <input
              {...register('address', {
                required: true,
                validate: validateAddress,
              })}
              autoComplete="off"
              onChange={handleInputChange}
              onFocus={() => setaddressFocused(true)}
              onBlur={() => setaddressFocused(false)}
              type="text"
              id="address"
              className={`block w-full border-none  p-1.5 text-sm font-medium placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0`}
              placeholder={t('modalNewClient.address')}
            />
            {errors.address?.type === 'validate' && (
              <p
                className="absolute right-0 top-[-19px] text-[12px] font-bold text-redish"
                role="alert"
              >
                {t('modalNewClient.address-error')}
              </p>
            )}
            {showAddressList && (
              <div className="geocoding-datalist absolute top-10 z-30 flex max-h-[160px] w-full flex-col overflow-y-auto border-[1px] border-graylight bg-white">
                {addressSuggestions.map((option, index) => (
                  <div
                    className="flex h-full w-full cursor-pointer border-b-[1px] border-graydark p-1 text-[12px] font-medium hover:bg-graydark"
                    key={index}
                    onClick={() => handleAddressSelection(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Email Input */}
        <InputField
          label={t('modalNewClient.email')}
          selectedValue={email}
          doNotUseSaveSelfButton
          type="text"
          icon={emailIcon}
          errors={errors.email}
          register={register}
          registerName="email"
          validationSchema={{
            // required: t('global.required'),
            pattern: {
              value: EMAIL_REGEX,
              message: t('global.email-error'),
            },
          }}
        />

        {/* Save Button */}
        <div className="flex justify-end">
          <Button
            text={t('modalNewClient.save')}
            variant="green"
            icon={saveBigIcon}
            disabled={createClientLoading}
            loading={createClientLoading}
            onClick={handleSubmit(onSubmit)}
            type="button"
            id="save-new-client"
          />
        </div>
      </form>
    </>
  );
};

export default NewClient;
